<template>
  <div>
    <h4>
      Bestanden
    </h4>

    <b-table v-if="attachments.length" class="table" :items="attachments" :fields="fields">
      <template v-slot:cell(AttachmentUrl)="data">
        <a :href="`http://${data.item.AttachmentUrl}`" target="_BLANK"
          >Download</a
        >
      </template>
    </b-table>
    <div v-else class="text-center w-100 mt-5 mb-5">
      <h4 class="text-secondary">Geen bestanden gevonden</h4>
    </div>
  </div>
</template>

<script>
import { getSerialAttachments } from '@/services/SerialService'
import { BTable } from 'bootstrap-vue'

export default {
  components: {
    BTable
  },
  props: {
    serialid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      attachments: [],
      fields: [
        {
          key: 'FileName',
          label: 'Bestandsnaam'
        },
        {
          key: 'AttachmentUrl',
          label: 'Download link'
        }
      ]
    }
  },
  async created() {
    const attachments = await getSerialAttachments({
      serialID: this.serialid,
      customerID: this.$store.state.customerID
    })
    this.attachments = attachments.Collection
  }
}
</script>

<style></style>
