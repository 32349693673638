const { axiosCreate } = require('./Api')
import store from '../state/store'

export async function getSerial({ serialID }) {
  const result = await axiosCreate.get(`shop/serials/${serialID}`, {
    params: { contactID: store.getters.contactID },
    headers: { authorization: store.getters.token }
  })

  return result.data
}

export async function getSerialAttachments({ serialID }) {
  const result = await axiosCreate.get(`shop/serials/${serialID}/attachments`, {
    params: { contactID: store.getters.contactID },
    headers: { authorization: store.getters.token }
  })
  return result.data
}

export async function getSerialServices({ serialID }) {
  const result = await axiosCreate.get(`shop/serials/${serialID}/services`, {
    params: { contactID: store.getters.contactID },
    headers: { authorization: store.getters.token }
  })
  return result.data
}
